import React from 'react';
import Collapsible from 'react-collapsible';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { faqData } from '../utils';
import './faq.css';

const Faq = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Typography variant='h3' color='textSecondary'>FAQs</Typography>
      </Box>
      {faqData.map((item, index) => {
        return (
        <Collapsible trigger={<Typography variant='button' component='p' className={classes.question} key={index.toString()}>{item.question}</Typography>}>
          <Typography variant='body1' color='textPrimary' style={{whiteSpace: 'pre-wrap'}}>{item.answer}</Typography>
        </Collapsible>
      )})}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
  },
  question: {
    fontSize: '1.5rem',
    letterSpacing: '0.5px'
  }
}));

export default Faq;
