import { roadmapData } from '../utils';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import StarIcon from '@material-ui/icons/Star';
import 'react-vertical-timeline-component/style.min.css';
import one from '../assets/1.svg'
import two from '../assets/2.svg'
import three from '../assets/3.svg'
import four from '../assets/4.svg'

const imgs = [one, two, three, four];

const Icon = ({ img, transform}) => {
  return <img src={img} alt='icon' style={{ width: '95%', height: '95%'}} />;
};

const Roadmap = () => {
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.up(1169));
  const classes = useStyles();
  return (
    <Box component='div' className={classes.root}>
      <Box className={classes.heading}>
        <Typography variant='h3' align='center' color="textSecondary" >ROADMAP</Typography>
      </Box>
      <VerticalTimeline>
        {roadmapData.map((item, index) => {
          return (
          <VerticalTimelineElement
            key={index}
            contentStyle={contentStyle}
            contentArrowStyle={contentArrowStyle}
            iconStyle={iconStyle}
            icon={<Icon img={ imgs[index] }/>}
          >
            <Typography variant='h6' color='textSecondary'>
              {item?.heading}
            </Typography>
            <Typography variant='body1' color='textPrimary'>
              {item?.description}
            </Typography>
          </VerticalTimelineElement>
        )})}

        <VerticalTimelineElement
          iconStyle={endIcon}
          icon={<StarIcon />}
        ></VerticalTimelineElement>
      </VerticalTimeline>
    </Box>
  );
};

const iconStyle = { background: 'rgb(255,255,255)' };
const endIcon = { background: '#FFF', color: '#2d4d83' };
const contentStyle = { background: '#000000a0', borderRadius: '12px' };
const contentArrowStyle = { borderRight: '7px solid #1D2D3C' };

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(20),
  },
  heading: {
    display: 'flex',
    marginBottom: theme.spacing(5),
    justifyContent: 'center',
  },
}));

export default Roadmap;
