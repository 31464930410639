import shark1 from "./assets/team1.jpg";
import shark2 from "./assets/team2.png";
import shark3 from "./assets/team3.png";
import shark4 from "./assets/team4.png";

export const footerData = {
  description: "Smart contract",
  link: "https://etherscan.io/address/0x6f9ffcfeb9288809e6407fe4b74eefd501b141ef#code",
};

export const aboutData = {
  title: "THE OFFICIAL AVATARS OF THE METAVERSE",
  description:
    "The Metaverse is the layer of virtual space that exists on top of everything else. It has an existence, but it’s world isn’t bound to any given universe, but rather coexists with all of them. We created the Metaversies to combine worlds that co-exist and interact with each other, represented as virtual spaces that users can access through avatars in the Metaverse.",
};

export const teamData = {
  title: "OUR TEAM",
  members: [
    {
      name: "Jack",
      designation: "Head of Operations / Marketing",
      image: shark1,
    },
    {
      name: "Hammad",
      designation: "Developer",
      image: shark2,
    },
    {
      name: "Jasper",
      designation: "Designer",
      image: shark3,
    },
    {
      name: "Cyrus",
      designation: "Community Manager / Marketing",
      image: shark4,
    },
  ],
};

export const roadmapData = [
  {
    heading: "25%",
    description:
      "Build a strong community through marketing, strategic partnerships, and giveaways.",
  },
  {
    heading: "50%",
    description:
      "Launch The Metaversies NFTs for minting by implementing a fair launch system.",
  },
  {
    heading: "75%",
    description: "Launch Metaversies P2E game.",
  },
  {
    heading: "100%",
    description:
      "Establish the Metaversies as the default avatars in the Metaversies game by licensing out the Metaversies NFTs which will reward every Metaversie holder passively using a royalty fee system since everyone will be using these avatars in the game.",
  },
];

export const faqData = [
  {
    question: "HOW MUCH DOES A METAVERSIE COST TO MINT?",
    answer: "0.08 ETH.",
  },
  {
    question: "WHAT BLOCKCHAIN IS THIS ON?",
    answer:
      "The collection of the 10,000 unique Metaversies are ERC-721 tokens on the Ethereum blockchain and will be available to freely trade on OpenSea post mint.",
  },
  {
    question: "WHEN IS THE OFFICIAL DROP DATE?",
    answer: "The NFT mint and game goes live in early 2022. Official date TBD",
  },
];

export const mintData = {
  title: "SOLD OUT!",
  description: "If you're late to the launch, you can refer to the ",
  link: "Secondary Market.",
};

export const headerData = {
  socialLinks: {
    twitter: "https://twitter.com/metaversies",
    discord: "https://discord.com/invite/4fUuDYdmh5",
    instagram: "https://www.instagram.com/metaversies/",
    opensea: "https://opensea.io/collection/metaversies",
  },
};
