import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 220,
    backgroundColor: '#00000000'
  },
  media: {
    height: 225,
    width: '100%'
  },
  content: {
    position: 'relative',
    height:'96px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    color:'#262626',
    letterSpacing: '0.5px'
  },
}));

export default function MediaCard({img, name, designation}) {
  const classes = useStyles();

  return (
    <Card className={classes.root} raised={true}>
      <CardActionArea href='' >
        <CardMedia
          component="img"
          className={classes.media}
          image={img}
          title={name}
        />
        <CardContent className={classes.content}>
          <Typography variant="button" component="p">
            {name}
          </Typography>
          <Typography variant="body1" component="p">
            {designation}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
