import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {footerData} from '../utils'

export default function Footer(params) {
  const classes = useStyles();
  return (
    <footer>
      <Box component='div' className={classes.root}>
        <Typography variant='h3' component='span'>
          <Link underline='always' href={footerData.link}>
            {footerData?.description}
          </Link>
        </Typography>
      </Box>
    </footer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '5px',
    marginTop: theme.spacing(15),
  },
}));
